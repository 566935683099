import LinkButton from '@components/shared/NavLinkColor'
import { NEW_DEALER_ACCOUNT_PAGE } from '@constants/routes'
import Icon from '@components/shared/Icon'

export default function NewFirmButton () {
    return <LinkButton
        t="Dodaj nową firmę"
        to={NEW_DEALER_ACCOUNT_PAGE}
        color="blue"
        iconLeft={<Icon>add</Icon>} />
}