const DOC_TYPE_INFO = {
    'null': {
        name: 'Aktualności',
        heading: 'Aktualności',
        slug: 'aktualnosci-wiadomosci-motoryzacja',
        legacySlug: 'aktualnosci-wiadomosci-motoryzacja',
        seoPage: 'blog-kategoria-aktualnosci',
        baner: 'dpl-blog-desktop',
        mobileBaner: 'dpl-blog-mobile',
    },
    'tech-data': {
        name: 'Dane techniczne',
        heading: 'Dane techniczne',
        slug: 'dane-techniczne-samochodow',
        legacySlug: null,
        seoPage: 'blog-kategoria-dane-techniczne',
        baner: 'dpl-blog-desktop',
        mobileBaner: 'dpl-blog-mobile',
    },
    // 'make-history': {
    //     name: 'Historia marek samochodowych',
    //     heading: 'Historia marek samochodowych',
    //     slug: 'historia-marek-samochodowych',
    //     legacySlug: 'historia-marek-samochodowych',
    //     seoPage: 'blog-kategoria-historia-marek',
    //     baner: 'samochody-dostawcze-uzywane-i-nowe-na-sprzedaz',
    //     mobileBaner: 'samochody-dostawcze-uzywane-i-nowe-na-sprzedaz',
    // },
    // 'car-selling': {
    //     name: 'Internetowe oferty',
    //     heading: 'Internetowe oferty',
    //     slug: 'internetowe-oferty', // ogloszenia
    //     legacySlug: 'ogloszenia-motoryzacyjne-auta-sprzedaz',
    //     legacySlugV2: 'ogloszenia-motoryzacyjne',
    //     seoPage: 'blog-kategoria-ogloszenia',
    //     baner: 'ogloszenia-motoryzacyjne-auta-sprzedaz',
    //     mobileBaner: 'ogloszenia-motoryzacyjne-auta-sprzedaz',
    //     backLink: ['/', 'Darmowe ogłoszenia'],
    // },
    // 'used-cars': {
    //     name: 'Rynek wtórny',
    //     heading: 'Rynek wtórny',
    //     slug: 'rynek-wtorny', // uzywane
    //     legacySlug: 'samochody-uzywane-auta',
    //     legacySlugV2: 'samochody-uzywane-auta',
    //     seoPage: 'blog-kategoria-uzywane',
    //     baner: 'samochody-uzywane-auta',
    //     mobileBaner: 'samochody-uzywane-auta',
    //     backLink: ['/samochody-osobowe?stan=Uzywany', 'Samochody używane'],
    // },
    'new-cars': {
        name: 'Nowości',
        heading: 'Nowości',
        slug: 'nowosci', // nowe
        legacySlug: 'nowe-samochody-auta',
        legacySlugV2: 'nowe-samochody-auta',
        seoPage: 'blog-kategoria-nowe',
        baner: 'dpl-blog-desktop',
        mobileBaner: 'dpl-blog-mobile',
        backLink: ['/samochody-dostawcze?stan=Nowy', 'Nowe samochody'],
    },
    // 'hybrid-cars': {
    //     name: 'Hybrydowe',
    //     heading: 'Auta hybrydowe',
    //     slug: 'hybrydowe',
    //     legacySlug: '',
    //     legacySlugV2: '',
    //     seoPage: 'blog-kategoria-hybrydowe',
    //     baner: 'nowe-samochody-auta',
    //     mobileBaner: 'nowe-samochody-auta',
    //     backLink: ['/samochody-osobowe?paliwo=Hybryda', 'Hybrydy'],
    // },
    // 'electric-cars': {
    //     name: 'Elektryczne',
    //     heading: 'Elektryczne',
    //     slug: 'elektryczne',
    //     legacySlug: '',
    //     legacySlugV2: '',
    //     seoPage: 'blog-kategoria-elektryczne',
    //     baner: 'nowe-samochody-auta',
    //     mobileBaner: 'nowe-samochody-auta',
    //     backLink: ['/samochody-osobowe?paliwo=Elektro', 'Elektryczne'],
    // },
    // 'cars-for-parts': {
    //     name: 'Samochody na części',
    //     heading: 'Samochody na części - szroty i stacje demontażu',
    //     slug: 'samochody-na-czesci-auta',
    //     legacySlug: 'samochody-na-czesci-auta',
    //     seoPage: 'blog-kategoria-na-czesci',
    //     baner: 'czesci-motoryzacyjne-na-sprzedaz',
    //     mobileBaner: 'czesci-motoryzacyjne-na-sprzedaz',
    // },
    // 'car-installment': {
    //     name: 'Samochody na raty',
    //     heading: 'Samochody na raty, kredyt - leasing',
    //     slug: 'samochod-na-raty-auta',
    //     legacySlug: 'samochod-na-raty-auta',
    //     seoPage: 'blog-kategoria-na-raty',
    //     baner: 'przyczepy-i-naczepy-na-sprzedaz',
    //     mobileBaner: 'przyczepy-i-naczepy-na-sprzedaz',
    // },
    // 'car-insurance': {
    //     name: 'Ubezpieczenie samochodu',
    //     heading: 'Ubezpieczenie samochodu - polisy OC AC NNW',
    //     slug: 'ubezpieczenie-samochodu-OC-AC-auta',
    //     legacySlug: 'ubezpieczenie-samochodu-OC-AC-auta',
    //     seoPage: 'blog-kategoria-ubezpieczenie',
    //     baner: 'narzedzia-warsztatowe-i-wyposazenie-serwisu-warsztatowego',
    //     mobileBaner: 'narzedzia-warsztatowe-i-wyposazenie-serwisu-warsztatowego',
    // },
}

const DEFAULTS = {
    TAG_ARTICLES_SEO_PAGE: 'TaggedArticles',
    ARTICLES_SEO_PAGE: 'Articles',
    BANER_PATH: '/backgrounds/',
}

const SLUG_TO_DOCTYPE = {}
const SLUG_TO_SEO_PAGE = {}
const SLUG_TO_NAME = {}
Object
    .keys(DOC_TYPE_INFO)
    .forEach(key => {
        const propSet = DOC_TYPE_INFO[key]
        const { name, slug, seoPage } = propSet

        SLUG_TO_DOCTYPE[slug] = key
        SLUG_TO_SEO_PAGE[slug] = seoPage
        SLUG_TO_NAME[slug] = name
    })

function docTypeIsValid (docType) {
    return docType === null || Boolean(DOC_TYPE_INFO[docType])
}

module.exports = {
    DOC_TYPE_INFO,
    DEFAULTS,
    SLUG_TO_DOCTYPE,
    SLUG_TO_SEO_PAGE,
    SLUG_TO_NAME,

    docTypeIsValid,
}