import { REGULATIONS_PAGE } from './routes'

const HOST = process.env.NEXT_PUBLIC_HOST

const Business = {
    owner: 'POLMARKET',
    ownerAlt: 'Grupa Polmarket',
    logo: 'http://drive.google.com/uc?export=view&id=1TGT94uA-My19Z2_eAkHzHpDxMI_6Jr-L',
    image: 'http://drive.google.com/uc?export=view&id=1EE5QHWrsIugIpPgoTBbNy4Vi-4g_7SRG',
    firm: 'POLMARKET',
    nip: '9730672604',
    regon: '380872518',
    name: 'Dostawczaki.pl',
    www: 'https://dostawczaki.pl/',
    email: 'kontakt@dostawczaki.pl',
    phone: '+48 530 60 60 40',
    address: 'ul. Poznańska 20B,\n65-128 Zielona Góra',
    host: HOST,
    hosting: 'kei',
    copyright: 'Copyright ©' + new Date().getFullYear() + '.',
    facebook: 'https://www.facebook.com/portalsamochodypl/',
    twitter: 'https://twitter.com/samochody_pl',
    instagram: 'https://www.instagram.com/pl_samochody/',
    regulations: HOST + REGULATIONS_PAGE,
    invoice: {
        firm: 'POLMARKET PL SP Z O.O. SPÓŁKA KOMANDYTOWA',
        address: 'ul. Poznańska 20B\n65-137 Zielona Góra',
        NIP: '9731059426'
    },
}

export default Business