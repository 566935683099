import { DOC_TYPE_INFO } from '@constants/articles/docTypes'

const CATEGORY_WISE_ROUTE = '/informacje-motoryzacja/kategoria/'

function categoryLink (post) {
    return CATEGORY_WISE_ROUTE + categorySlug(post)
}

function categorySlug (post) {
    const { docType = null } = post
    return (DOC_TYPE_INFO[docType] || {}).slug
}

export default categoryLink