import Navbar from '@components/shared/Navbar'
import NavbarMobileButtons from '@components/shared/NavbarMobileButtons'
import Footer from '@components/shared/Footer'

import withBreakpoints from '@hoc/withBreakpoints'
import BREAKPOINTS from '@constants/breakpoints'

import styles from 'styles/Main.module.css'

const MainLayout = ({ breakpoints, children, footerArticle }) => {
    const { showHamburger: showButtons } = breakpoints

    return (
        <main className={styles.main}>
            <Navbar breakpoints={breakpoints} />
            { showButtons && <NavbarMobileButtons /> }
            { children }
            <Footer footerArticle={footerArticle} />
        </main>
    )
}

export default withBreakpoints(BREAKPOINTS)(MainLayout)