import { useRouter } from 'next/router'
import { addOfferLink, addPartnerProductLink,
    addPartnerArticleLink } from '@components/shared/Navbar'
import {
    NEW_ACCOUNT_PAGE,
    NEW_DEALER_ACCOUNT_PAGE,
    // NEW_PARTNER_ACCOUNT_PAGE,
} from '@constants/routes'
import NavLinkColor from './NavLinkColor'
import Maybe from '@components/shared/Maybe'
import Icon from '@components/shared/Icon'
import NewFirmButton from '@components/firmbase/NewFirmButton'
import withUser from '@hoc/withUser'

import styles from 'styles/layout/NavbarMobileButtons.module.css'

const hideAddOfferOn = ['dodaj-ogloszenie', 'cennik', 'informacje-motoryzacja', 'baza-firm']
const showLoginOn = ['konto/zaloguj', 'konto/nowe']
const showNewFirmOn = ['baza-firm']

const NavbarMobileButtons = ({ user }) => {
    const router = useRouter()
    const pathname = router.pathname.slice(1)
    const isPartner = user?.is_partner

    const showAddOffers = shouldNotHide(hideAddOfferOn, pathname)
    const showFirmButton = shouldDisplay(showNewFirmOn, pathname)
    const showLoginButtons = shouldDisplay(showLoginOn, pathname)
    const showPartnerAddOffers = isPartner && showAddOffers
    const showRegularAddOffers = !isPartner && showAddOffers

    const addIcon = <Icon>add</Icon>
    return (
        <div className={styles.wrap}>
            <Maybe test={showRegularAddOffers}>
                <NavLinkColor {...addOfferLink} color="blue" iconLeft={addIcon} />
            </Maybe>

            <Maybe test={showLoginButtons}>
                <br/>
                <NavLinkColor t="Załóż konto firmowe" to={NEW_DEALER_ACCOUNT_PAGE}
                    color="teal" iconLeft={addIcon} />

                <br/>
                {/* <NavLinkColor t="Załóż konto partnera" to={NEW_PARTNER_ACCOUNT_PAGE}
                    color="blue" iconLeft={addIcon} /> */}

                <NavLinkColor t="Załóż konto prywatne" to={NEW_ACCOUNT_PAGE}
                    color="blue" iconLeft={addIcon} />
            </Maybe>

            <Maybe test={showPartnerAddOffers}>
                <div style={{display:'flex',width:'100%'}}>
                    <NavLinkColor {...addPartnerProductLink} color="blue" iconLeft={addIcon} grow />
                    <NavLinkColor {...addPartnerArticleLink} color="blue" iconLeft={addIcon} grow />
                </div>
            </Maybe>

            <Maybe test={showFirmButton}>
                <NewFirmButton />
            </Maybe>
        </div>
    )
}

function shouldDisplay (conditions, pathname) {
    return conditions.some(phrase => pathname.startsWith(phrase))
}

function shouldNotHide (conditions, pathname) {
    return !shouldDisplay(conditions, pathname)
}

export default withUser(NavbarMobileButtons)