import Link from 'next/link'
import { useRouter } from 'next/router'
import Logo from './Logo'
import Article from '@components/html/Article'
import useT from '@hooks/useTranslation'

import b from '@constants/Business'
import { CONTACT_PAGE, FIRM_BASE_PAGE, PRICING_PAGE,
    ABOUT_US_PAGE,
    PRIVACY_PAGE, REGULATIONS_PAGE, INTEGRATION_PAGE,
    SALE_DOC_PAGE, COLISION_DOC_PAGE, SALE_BUY_DOC_PAGE,
    SITE_MAPS_PAGE,
    NEWS_PAGE, } from '@constants/routes'

import { DOC_TYPE_INFO } from '@constants/articles/docTypes'
import blogCategory from '@utils/links/blogCategory'
// import apiDocLink from '@utils/links/apiDocLink'

import styles from 'styles/Footer.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

const Footer = ({ footerArticle, Layout = FooterLayout }) => {
    return (
        <div role="contentinfo">
            <footer className={styles.footer}>
                <div className="container">
                    <Layout />
                </div>
                <div className={styles.copyright}>
                    <div className="container" style={{color:'#fff'}}>
                        {b.copyright} {useT('Właścicielem portalu jest')} <span>{b.ownerAlt}</span>
                    </div>
                </div>
            </footer>
            { footerArticle &&
                <div className="container">
                    <Article article={footerArticle} id="artykul-stopka" />
                </div> }
        </div>
    )
}

function FooterLayout () {
    return (
        <FooterGrid>
            <FooterBrand />
            <FooterContact />
            <FooterNews />
            <FooterInformation />
        </FooterGrid>
    )
}

export function FooterGrid ({ children }) {
    return <div className={styles.grid}>{children}</div>
}

export function FooterBrand () {
    return (
        <div>
            <Logo bg="dark" />
            <SocialMedia />
        </div>
    )
}

function FooterContact () {
    return (
        <div>
            <h3>{useT('Kontakt')}</h3>
            <p><FooterLink to={CONTACT_PAGE}>{useT('Formularz kontaktowy')}</FooterLink></p>
            <p><Email email={b.email} /></p>
            {/* <p><Phone phone={b.phone} /></p> */}
            {/* <p>{b.address}</p> */}
        </div>
    )
}

export function FooterInformation ({ nodocs }) {
    const router = useRouter()
    const reloadCookieSettings = () => {
        document.cookie = 'cook_consent=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        router.push('/')
    }

    return (
        <div>
            <h3>{useT('Informacje')}</h3>
            <p>
                <FooterLink to={ABOUT_US_PAGE}>{useT('O nas')}</FooterLink>
            </p>
            <p>
                <FooterLink to={PRICING_PAGE}>{useT('Cennik - Pakiety na Ceny ogłoszeń')}</FooterLink>
            </p>
            {/* <p>
                <FooterLink to={apiDocLink()}>{useT('API dostawczaki.pl')}</FooterLink>
            </p> */}
            <p>
                <FooterLink to={PRIVACY_PAGE}>{useT('Polityka prywatności')}</FooterLink>
            </p>
            <p>
                <FooterLink to={REGULATIONS_PAGE}>{useT('Regulamin serwisu')}</FooterLink>
            </p>
            <p>
                <FooterLink to={INTEGRATION_PAGE}>{useT('Integracje')}</FooterLink>
            </p>
            { !nodocs && <FooterDocs /> }
            <p>
                <FooterLink to={SITE_MAPS_PAGE}>{useT('Mapa strony')}</FooterLink>
            </p>
            {
                !nodocs && <p>
                    <a href="#" onClick={reloadCookieSettings}>{useT('Ustawienia ciasteczek')}</a>
                </p>
            }
        </div>
    )
}

export function FooterNews () {
    return (
        <div>
            <h3>{useT('Motoryzacja')}</h3>
            <p>
                <FooterLink to={NEWS_PAGE}>{useT('Informacje motoryzacyjne')}</FooterLink>
            </p>
            <BlogCategories />
            <p>
                <FooterLink to={FIRM_BASE_PAGE}>{useT('Baza firm')}</FooterLink>
            </p>
        </div>
    )
}

function BlogCategories () {
    return Object
        .keys(DOC_TYPE_INFO)
        .map(docType => {
            const { name } = DOC_TYPE_INFO[docType]
            const to = blogCategory({ docType })

            return <p key={docType}><FooterLink to={to}>{useT(name)}</FooterLink></p>
        })
}

export function FooterDocs ({ suffix = ` - ${useT('wzór')}` }) {
    return (
        <>
            <p><FooterLink to={SALE_DOC_PAGE}>{useT('Umowa kupna-sprzedaży') + suffix}</FooterLink></p>
            <p><FooterLink to={COLISION_DOC_PAGE}>{useT('Oświadczenie sprawcy kolizji') + suffix}</FooterLink></p>
            <p><FooterLink to={SALE_BUY_DOC_PAGE}>{useT('Zgłoszenie sprzedaży samochodu')}</FooterLink></p>
        </>
    )
}

function SocialMedia () {
    return (
        <div className={styles.social_media}>
            <SocialMediaIcon id="facebook" to={b.facebook} />
            <SocialMediaIcon id="twitter" to={b.twitter} />
            <SocialMediaIcon id="instagram" to={b.instagram} />
        </div>
    )
}

function SocialMediaIcon ({ id, to }) {
    return (
        <a title={id} href={to} target="_blank" rel="noopener noreferrer">
            <img src={`/svg/${id}.svg`} alt={id} width="28" height="28" />
        </a>
    )
}

function FooterLink ({ to, children }) {
    return (
        <Link href={`${HOST}${to}`}>
            <a>{children}</a>
        </Link>
    )
}

function Email ({ email }) {
    if (typeof email !== 'string') return null
    return <Link href={`mailto:${email}`}>
        <a>{email}</a>
    </Link>
}

function Phone ({ phone }) {
    if (typeof phone !== 'string') return null
    return <Link href={`tel:${phone}`.replace(/\s+/g, '')}>
        <a>{phone}</a>
    </Link>
}

export default Footer